import { Card, Skeleton, Stack } from "@mui/material"

const ProductCardSkeleton = ({ width = "180px" }: { width: string }) => (
  <Card sx={{ width: width, p: "8px" }}>
    <Stack spacing={1}>
      <Skeleton variant="rounded" width="100%" height="144px" />
      <Skeleton variant="rounded" width="100px" height="22px" />
      <Skeleton variant="rounded" width="100%" height="10px" />
      <Skeleton variant="rounded" width="120px" height="10px" />
      <Skeleton variant="rounded" width="100px" height="10px" />
    </Stack>
  </Card>
)

export default ProductCardSkeleton
