import { ProductFilterGroup } from "./ProductSearch"
import React from "react"
import {
  Box,
  Typography,
  useTheme,
  Button,
  Stack,
  IconButton,
} from "@mui/material"
import { Close } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"

interface AppliedFilterProps {
  productFilterGroups: Array<ProductFilterGroup>
  setProductFilterGroups: React.Dispatch<
    React.SetStateAction<ProductFilterGroup[]>
  >
}

const AppliedFilter = ({
  productFilterGroups,
  setProductFilterGroups,
}: AppliedFilterProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  if (
    !productFilterGroups.find(
      (productFilterGroup) =>
        productFilterGroup.filters.find((filter) => filter.applied) !==
        undefined
    )
  ) {
    return null
  }

  return (
    <Box
      sx={{
        p: 2,
        border: `1px solid ${theme.palette.dividerColor?.main}`,
        borderRadius: 3,
        mb: 3,
        textAlign: "justify",
      }}
    >
      <Typography
        variant="h5"
        color={theme.palette.textPrimary.main}
        sx={{
          mb: "12px",
        }}
      >
        {t("productSearch.appliedFilters")}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        rowGap={1}
        flexWrap="wrap"
      >
        {productFilterGroups.map((productFilterGroup, i) => (
          <React.Fragment key={i}>
            {productFilterGroup.filters.map((filter, j) => {
              if (!filter.applied) return null
              return (
                <Box
                  key={j}
                  sx={{
                    color: theme.palette.textSecondary.main,
                    display: "inline-flex",
                    border: `1px solid ${theme.palette.dividerColor?.main}`,
                    borderRadius: 40,
                    pr: 0.8,
                    pl: 1.5,
                    py: 0.75,
                    // mx: 0.5,
                    // my: 0.4,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      display: "inline-block",
                      mr: 1,
                    }}
                  >
                    {filter.entry}{" "}
                  </Typography>
                  <IconButton
                    aria-label={filter.entry}
                    onClick={() => {
                      gaLogEvent({
                        eventName: EventName.navFilter_unclick,
                        parameters: {
                          item_location: t("productSearch.appliedFilters"),
                          item_message1: productFilterGroups[i].displayName,
                          item_message2: filter.entry,
                        },
                      })
                      setProductFilterGroups((productFilterGroups) => {
                        const newProductFilterGroups = [...productFilterGroups]
                        newProductFilterGroups[i].filters[j].applied = false
                        return newProductFilterGroups
                      })
                    }}
                  >
                    <Close
                      sx={{
                        color: theme.palette.ctaPrimaryText?.main,
                        borderRadius: 40,
                        padding: 0.3,
                        backgroundColor: theme.palette.ctaPrimary.main,
                        height: 18,
                        width: 18,
                        cursor: "pointer",
                      }}
                    />
                  </IconButton>
                </Box>
              )
            })}
          </React.Fragment>
        ))}
        <Button
          variant="text"
          sx={{
            fontWeight: 400,
            color: theme.palette.ctaPrimary.main,
          }}
          onClick={() => {
            setProductFilterGroups((productFilterGroups) =>
              productFilterGroups.map((productFilterGroup) => ({
                ...productFilterGroup,
                filters: productFilterGroup.filters.map((filter) => ({
                  ...filter,
                  applied: false,
                })),
              }))
            )
            gaLogEvent({
              eventName: EventName.navFilter_clearAll,
              parameters: {
                item_location: t("productSearch.appliedFilters"),
              },
            })
          }}
          aria-label={t("productSearch.clearAll")}
        >
          {t("productSearch.clearAll")}
        </Button>
      </Stack>
    </Box>
  )
}

export default AppliedFilter
