import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import {
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Box from "@mui/material/Box"
import { useTranslation } from "react-i18next"
import { ProductGrid, ProductGridItem } from "swiftlykit"
import { ProductSortDimension, ProductSummary } from "../../generated/graphql"
import SubCategories from "./subCategories/SubCategories"
import AppliedFilters from "../product/ProductSearch/AppliedFilters"
import LeftBarFilters from "../product/ProductSearch/LeftBarFilters"
import { CloseIcon, TuneIcon } from "@/icons"
import { SortDimensionStringMap } from "../product/ProductSearch/SortByMenu"
import { useRouter } from "next/router"
import {
  ProductFilter,
  ProductFilterGroup,
} from "../product/ProductSearch/ProductSearch"
import { com, swiftly } from "../../client-data-bom"
import Ads from "../ads/Ads"
import styles from "../../styles/component.module.scss"
import {
  addShoppingListItem,
  deleteShoppingListItem,
  openSnackbar,
  setOpenProductId,
  setPageAttributes,
} from "../../contexts/actions/actions"
import { AppContext, useAppContext } from "../../contexts/AppContext"
import Pagination from "./Pagination"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { BreadcrumbProp } from "components/breadcrumb/Breadcrumb"
import NoResultFound from "components/product/ProductSearch/NoResultFound"
import generateConfig from "../../configs/config"
import ProductGridSkeleton from "components/common/skeleton/ProductGridSkeleton"
import PaginationSkeleton from "components/common/skeleton/PaginationSkeleton"
import {
  MAX_ITEMS_COUNT,
  generateNewShoppingListItem,
  isItemPresentInShoppingList,
} from "components/shoppingList/utils"
import { ShoppingListItemType } from "components/shoppingList/types"
import { updateShoppingList } from "index.service"
import { useAuth } from "contexts/AuthContext"

export type FieldEntry = { count: number; readonly entry: string }

export interface ItemListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merchandisedCategory: any
  products: ProductSummary[]
  storeId?: string
  topAd?: swiftly.ads.SwiftlyJsResolvableAdWrapper
  bottomAd?: swiftly.ads.SwiftlyJsResolvableAdWrapper
  userId?: string
  isLoading?: boolean
  setSubCategories?: (subCategories: string[]) => void
  breadcrumbs?: BreadcrumbProp[]
}

const config = generateConfig()

export const productCardDisplayMessage = (
  item: any,
  noStoreMessage: string
) => {
  if (!item?.webPrice?.failure?.displayMessage) return undefined
  if (item?.webPrice?.failure?.type === "StoreNotSelected")
    return noStoreMessage
  return item?.webPrice?.failure?.displayMessage
}

export const headingStyles = {
  h1: {
    fontSize: "1.5rem",
  },
  h2: {
    fontSize: "1.25rem",
  },
  h3: {
    fontSize: "1.125rem",
  },
  h4: {
    fontSize: "1rem",
  },
}

const ItemList = ({
  merchandisedCategory,
  products,
  storeId,
  topAd,
  bottomAd,
  userId,
  isLoading,
  setSubCategories,
  breadcrumbs,
}: ItemListProps) => {
  const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(767))
  const isTablet = useMediaQuery("(min-width:767px) and (max-width:992px)")
  const {
    pageAttributes: { pageNumber, sortDirection, itemsPerPage, totalItems },
  } = useContext(AppContext).state
  const {
    dispatch,
    state: { eligibilitiesList },
  } = useContext(AppContext)
  const { t } = useTranslation()

  const [productFilterGroups, setProductFilterGroups] = useState<
    Array<ProductFilterGroup>
  >([])
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
  const resultsRef = useRef<HTMLDivElement | null>(null)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [sortBy, setSortBy] = useState<keyof typeof SortDimensionStringMap>(
    com.swiftly.feature.products.SwiftlyProductSortDimension.Featured.name
  )

  const { swiftlyToken } = useAuth()
  const {
    state: { shoppingList: shoppingListState },
  } = useAppContext()

  const isEmptyResponse: boolean = useMemo(
    () => Object.keys(products || []).length === 0,
    [products]
  )
  useEffect(() => {
    if (isFilterApplied) resultsRef.current && resultsRef.current.focus()
  }, [totalItems])

  // Commented for - https://dev.azure.com/prestoq/Retailer%20Platform/_workitems/edit/59238
  // const handleSortBy = (value: keyof typeof SortDimensionStringMap) => {
  //   setSortBy(value)
  //   // On sort filter changing to first page
  //   dispatch(setPageAttributes({ pageNumber: 1 }))
  // }

  useEffect(() => {
    // set filters only once
    if (!productFilterGroups?.length)
      setProductFilterGroups(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        merchandisedCategory?.products?.fieldFacets?.map((fieldFacet: any) => ({
          ...fieldFacet,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          filters: fieldFacet.entries.map((entry: any) => {
            return {
              ...entry,
              field: fieldFacet.field,
              displayName: fieldFacet.displayName || entry.entry,
              applied: !!productFilterGroups.find(
                (productFilterGroup) =>
                  productFilterGroup.field.name === fieldFacet.field.name &&
                  productFilterGroup.filters.find(
                    (productFilter) => productFilter.entry === entry.entry
                  )?.applied
              ),
              __: entry,
            }
          }),
          __: fieldFacet,
        })) || []
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchandisedCategory?.products])

  const filteredProducts = useMemo(() => {
    let partiallyFilteredProduct: typeof products = products ?? []
    for (const productFilterGroup of productFilterGroups) {
      const appliedFilters: Array<ProductFilter> = []
      for (const productFilter of productFilterGroup.filters) {
        if (productFilter.applied) {
          appliedFilters.push(productFilter)
        }
      }
      if (appliedFilters.length > 0) {
        partiallyFilteredProduct = partiallyFilteredProduct.filter(
          (product) => {
            for (const appliedFilter of appliedFilters) {
              if (
                product[
                  appliedFilter.field.name?.toLowerCase() as keyof typeof product
                ] === appliedFilter.entry ||
                product[
                  appliedFilter.displayName?.toLowerCase() as keyof typeof product
                ] === appliedFilter.entry
              ) {
                return true
              }
            }
            return false
          }
        )
        setIsFilterApplied(true)
      } else setIsFilterApplied(false)
    }
    return partiallyFilteredProduct
  }, [productFilterGroups, products])

  /**
   * Handle add an item to the shopping list
   * @param {ProductSummary} item
   */
  const handleAddItemToShoppingList = async (item: ProductSummary) => {
    const newItem: ShoppingListItemType = generateNewShoppingListItem(item)

    dispatch(addShoppingListItem(newItem))

    if (swiftlyToken?.access_token) {
      const items: ShoppingListItemType[] = [
        ...shoppingListState.shoppingListItems,
      ]

      await updateShoppingList(
        swiftlyToken.access_token,
        [...items, newItem],
        shoppingListState.metaData
      )
    }

    // Show notification
    dispatch(
      openSnackbar({
        variant: "alert",
        message: t("shoppingList.addItemSuccessAlert", {
          productName: item?.brand
            ? `${item.brand}, ${item.title}`
            : item.title,
        }),
        alert: {
          color: "success",
        },
      })
    )
  }

  /**
   * Remove an item from the shopping list
   * @param {ProductSummary} item
   */
  const handleRemoveItemFromShoppingList = async (item: ProductSummary) => {
    const itemKey = shoppingListState.shoppingListItems.find(
      (slItem) => slItem?.attributes?.productId === item.id
    )?.key

    if (itemKey) {
      dispatch(deleteShoppingListItem({ key: itemKey }))

      if (swiftlyToken?.access_token) {
        const filteredList = shoppingListState.shoppingListItems?.filter(
          (i: any) => i.key != item.id
        )

        await updateShoppingList(
          swiftlyToken.access_token,
          filteredList,
          shoppingListState.metaData
        )
      }

      dispatch(
        openSnackbar({
          variant: "alert",
          message: t("shoppingList.removeItemSuccessAlert", {
            productName: item.title,
          }),
          alert: {
            color: "success",
          },
        })
      )
    }
  }

  // TODO(handle other price scenarios)
  // TODO(handle price exceptions)
  const productSearchView = useMemo(() => {
    if (isEmptyResponse) {
      return []
    }
    const cards = filteredProducts.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any) =>
        ({
          id: item.id,
          brand: item?.brand || "",
          title: item?.brand || "",
          shortDescription: item?.title || "",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          imageUrl: item.images.find((image: any) => !!image.image)?.image?.uri,
          eligibilities:
            eligibilitiesList
              .filter((item: any) => item?.eligibilities?.includes(item.id))
              .map((obj: any) => obj?.id) || [],
          price: "",
          type: "product",
          tag: "empty", // TODO: Set the tag when API is ready, e.g. "new" | "featured" | "sponsored"
          ...(config.experimentalProductPricing === "true"
            ? {
                basePrice: item?.webPrice?.success?.basePrice,
                isSale: item?.webPrice?.success?.isSale ?? false,
                promoPrice: item?.webPrice?.success?.promoPrice,
                sx: {
                  card: {
                    height: "342px !important", // forcing height for experimental purposes
                  },
                },
                displayMessage: productCardDisplayMessage(
                  item,
                  t("products.modal.StoreNotSelected")
                ),
              }
            : {}),
          onCardClick: (id: string) => {
            config?.productDetailPageEnabled
              ? router.push(`/product/${id}`)
              : dispatch(setOpenProductId(id))

            gaLogEvent({
              eventName: EventName.product_view,
              parameters: {
                item_id: item.id,
                item_nameCategory: breadcrumbs && breadcrumbs[2]?.name,
                item_nameCategorySub:
                  breadcrumbs && breadcrumbs[3] && breadcrumbs[3]?.name,
                item_brandName: item.brand,
                item_productName: item.title,
                item_description: item.shortDescription,
                item_price: "",
              },
            })
          },
          allowAddToShoppingList: config.enableShoppingList,
          onAddItemToShoppingList: (id: string) => {
            handleAddItemToShoppingList(item)
          },
          isAddedToShoppingList: isItemPresentInShoppingList(
            shoppingListState.shoppingListItems,
            item.id
          ),
          onRemoveItemFromShoppingList: (id: string) => {
            handleRemoveItemFromShoppingList(item)
          },
          disableAddItemToShoppingList:
            shoppingListState.shoppingListItems.length >= MAX_ITEMS_COUNT,
        } as ProductGridItem)
    )

    let sortDirection = ProductSortDimension.Featured
    switch (sortBy) {
      case com.swiftly.feature.products.SwiftlyProductSortDimension.AlphaAsc
        .name:
        sortDirection = ProductSortDimension.AlphaAsc
        break
      case com.swiftly.feature.products.SwiftlyProductSortDimension.AlphaDesc
        .name:
        sortDirection = ProductSortDimension.AlphaDesc
        break
      default:
        sortDirection = ProductSortDimension.Featured
        break
    }
    dispatch(setPageAttributes({ sortDirection: sortDirection }))

    return cards
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEmptyResponse,
    filteredProducts,
    dispatch,
    sortBy,
    shoppingListState.shoppingListItems,
  ])

  useEffect(() => {
    const updatedSubCategories = productFilterGroups.flatMap((item) =>
      item?.filters
        ?.filter((subCategory) => subCategory.applied)
        .map((subCategory) => subCategory.entry)
    )
    setSubCategories && setSubCategories(updatedSubCategories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productFilterGroups])

  useEffect(() => {
    if (productFilterGroups?.length) {
      dispatch(
        setPageAttributes({
          pageNumber: 1,
        })
      )
    }
  }, [productFilterGroups, dispatch])

  return (
    <Container maxWidth="xl">
      <Box pb={3} width="100%">
        <SubCategories
          storeId={storeId}
          merchandisedCategory={merchandisedCategory}
          categories={merchandisedCategory.navOptionsCategories}
        />
        <Typography
          variant="h1"
          fontWeight={600}
          sx={{ mt: 2, mb: 3, ...headingStyles.h1 }}
        >
          {merchandisedCategory?.displayName}
        </Typography>
        <Ads
          adWrapper={topAd}
          userId={userId}
          showEmptyAdHint={true}
          overrideSx={{ my: 3 }}
        />
        {!isLoading &&
        isEmptyResponse &&
        !productFilterGroups.some((group) =>
          group.filters.some((filter) => filter.applied)
        ) ? (
          <NoResultFound
            isCategory
            search={merchandisedCategory?.displayName}
          />
        ) : (
          <>
            <Box width="100%" mt={3}>
              {!isMobile && (
                <AppliedFilters
                  productFilterGroups={productFilterGroups}
                  setProductFilterGroups={setProductFilterGroups}
                />
              )}

              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="flex-start"
                gap={3}
              >
                {isMobile ? null : (
                  <Box
                    sx={{
                      width: 180,
                      display: "flex",
                      flexGrow: 0,
                      flexDirection: "column",
                      flexShrink: 1,
                    }}
                  >
                    <LeftBarFilters
                      title={t("productSearch.filterBy")}
                      productFilterGroups={productFilterGroups}
                      setProductFilterGroups={setProductFilterGroups}
                      setIsFilterOpen={setIsFilterOpen}
                    />
                  </Box>
                )}

                <Box
                  ref={resultsRef}
                  tabIndex={0}
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    flexShrink: 1,
                    mt: isMobile ? 0 : isTablet ? 0 : -1,
                  }}
                >
                  {isMobile ? (
                    <Stack
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      alignSelf="stretch"
                      width="inherit"
                      px={2}
                      pr="10px"
                      py="2px"
                      sx={{
                        backgroundColor: "lightPrimary.main",
                        borderRadius: 2,
                      }}
                      mb={2}
                    >
                      <Typography color="textSecondary.main" fontWeight={400}>
                        {t("common.results", { count: totalItems })}
                      </Typography>
                      <Button
                        startIcon={
                          <TuneIcon color={theme.palette.ctaPrimary.main} />
                        }
                        color="textPrimary"
                        sx={{ fontWeight: 600 }}
                        onClick={(e) => {
                          e.preventDefault()
                          setIsFilterOpen(true)
                        }}
                        aria-label={t("couponsRefine.refine")}
                      >
                        {t("couponsRefine.refine")}
                      </Button>
                    </Stack>
                  ) : isLoading ? (
                    <PaginationSkeleton sx={{ mb: 3 }} />
                  ) : (
                    <>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems={isTablet ? "flex-start" : "center"}
                        mb={3}
                      >
                        <Typography
                          color="textSecondary.main"
                          fontWeight={400}
                          width="100%"
                          maxWidth="fit-content"
                          alignSelf="center"
                        >
                          {t("common.results", {
                            count: totalItems,
                          })}
                        </Typography>
                        <Grid
                          container
                          direction="row"
                          justifyContent="end"
                          alignItems={isTablet ? "end" : "center"}
                          flexDirection={isTablet ? "column-reverse" : "row"}
                          width="auto"
                        >
                          <Grid container direction="row">
                            <Pagination />
                            {/* Commented for - https://dev.azure.com/prestoq/Retailer%20Platform/_workitems/edit/59058 */}
                            {/* <SortByMenu
                              value={sortBy}
                              onChange={handleSortBy}
                            /> */}
                          </Grid>
                        </Grid>
                      </Stack>
                    </>
                  )}
                  {isLoading ? (
                    <ProductGridSkeleton />
                  ) : (
                    <Box
                      className={styles.productC}
                      data-cy="categories-product-grid-container"
                    >
                      {productFilterGroups.some((group) =>
                        group.filters.some((filter) => filter.applied)
                      ) && isEmptyResponse ? (
                        <NoResultFound
                          isCategory
                          search={merchandisedCategory?.displayName}
                        />
                      ) : (
                        <ProductGrid cards={productSearchView} />
                      )}
                    </Box>
                  )}
                </Box>
              </Stack>
            </Box>
            {/* Pagination at end of products card/filters */}
            {!isLoading && (
              <Stack spacing={2} alignItems="flex-end">
                <Pagination />
              </Stack>
            )}
          </>
        )}
        <Ads
          adWrapper={bottomAd}
          userId={userId}
          showEmptyAdHint={true}
          overrideSx={{ mt: 3 }}
        />
        <Drawer
          anchor="right"
          open={isMobile && isFilterOpen}
          onClose={() => setIsFilterOpen(false)}
        >
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            alignSelf="stretch"
            width="inherit"
            sx={{
              position: "sticky",
              top: 0,
              bgcolor: "bgPrimary.main",
              zIndex: 100,
              p: 1,
              pl: 2,
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.08)",
            }}
          >
            <Typography
              sx={{
                ...headingStyles.h3,
              }}
              variant="h4"
            >
              {t("productSearch.filterBy")}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                setIsFilterOpen(false)
              }}
              aria-label={t("ariaLabels.closeFilter")}
            >
              <CloseIcon color={theme.palette.ctaPrimary.main} />
            </IconButton>
          </Stack>
          <Stack width="80vw" p={2} pb={0}>
            <LeftBarFilters
              productFilterGroups={productFilterGroups}
              setProductFilterGroups={setProductFilterGroups}
              setIsFilterOpen={setIsFilterOpen}
            />
          </Stack>
        </Drawer>
      </Box>
    </Container>
  )
}

export default ItemList
