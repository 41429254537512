import ListItem from "@mui/material/ListItem"
import Stack from "@mui/material/Stack"
import { useRouter } from "next/router"
import { CategoryAvatar } from "swiftlykit"
import { useTheme } from "@mui/material/styles"
import styles from "../../../styles/component.module.scss"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"

export interface SubCategoriesProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categories: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merchandisedCategory: any
  storeId?: string
}

// TODO: add proper types
const SubCategories = ({
  categories,
  merchandisedCategory,
  storeId,
}: SubCategoriesProps) => {
  const router = useRouter()
  const theme = useTheme()

  const menuClicked = (e: any, category: any) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      const categoryIdSanitized = encodeURIComponent(category?.id || "")
      router.push({
        pathname: "/categories/[categoryId]",
        query: { categoryId: categoryIdSanitized },
      })

      gaLogEvent({
        eventName: EventName.navProdCat_click,
        parameters: {
          item_nameCategory: category?.parents[0]?.split("/")?.[3],
          item_nameCategorySub: category?.displayName,
          item_location: "top of page",
        },
      })
    }
  }

  const graphAsMap = merchandisedCategory.taxonomy.graph.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc: any, node: any) => {
      acc[node.id] = node
      return acc
    },
    {}
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subCategory = categories.map((category: any) => {
    const categoryNode = graphAsMap[category.id]

    if (!categoryNode || !categoryNode.images) {
        return null;
    }

    const image = categoryNode.images
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((image: any) => {
        return (
          image.images
            ?.map((imageInfo: any) => {
              const imageUrl = imageInfo.uri

              if (imageUrl) {
                return imageUrl
              }
              return false
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .find((url: any) => !!url)
        )
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .find((url: any) => !!url)

    return (
      <ListItem
        key={`category-${category.id}`}
        disablePadding
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minWidth: 160,
          maxWidth: 160,
          borderRadius: 3,
          border: category?.active
            ? `2px solid ${theme.palette.ctaPrimary.main}`
            : `2px solid ${theme.palette.bgPrimary.main}`,
          p: 1,
          "&:hover": {
            border: `2px solid ${theme.palette.ctaPrimary.main}`,
          },
          cursor: "pointer",
        }}
        tabIndex={0}
        role="button"
        aria-label={category.displayName}
        onClick={(e) => {
          menuClicked(e, category)
        }}
        onKeyDown={(e) => {
          menuClicked(e, category)
        }}
        data-cy="subcategory-avatar-container"
      >
        <CategoryAvatar
          name={category.displayName}
          imgURL={image ? image : ""}
          width={80}
          height={80}
          // eslint-disable-next-line
          variant={"label" as any}
          showBorder={false}
        />
      </ListItem>
    )
  })

  return (
    <Stack
      direction="row"
      gap={2}
      component="ul"
      className={styles.customScroll}
      sx={{ overflowX: "auto" }}
    >
      {subCategory}
    </Stack>
  )
}

export default SubCategories
