import { Skeleton, Stack, SxProps } from "@mui/material"

const PaginationSkeleton = ({ sx }: { sx?: SxProps }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    mb={3}
    sx={sx}
  >
    <Skeleton variant="rounded" width="100px" height="32px" />
    <Stack spacing={1} direction="row">
      <Skeleton variant="rounded" width="100px" height="32px" />
      <Skeleton variant="rounded" width="32px" height="32px" />
      <Skeleton variant="rounded" width="32px" height="32px" />
    </Stack>
  </Stack>
)

export default PaginationSkeleton
