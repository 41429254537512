import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { Grid, IconButton, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"

import { AppContext } from "contexts/AppContext"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import { setPageAttributes } from "contexts/actions/actions"
import { useTranslation } from "react-i18next"

const Pagination = () => {
  const [totalPaginationPages, setTotalPaginationPages] = useState(0)

  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const {
    pageAttributes: { totalItems, pageNumber, itemsPerPage },
  } = useContext(AppContext).state

  useEffect(() => {
    setTotalPaginationPages(Math.ceil(totalItems / 50))
  }, [totalItems])

  const handlePageChange = (page: number) => {
    !!window &&
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    dispatch(setPageAttributes({ pageNumber: pageNumber + page }))
    gaLogEvent({
      eventName: EventName.navPage_arrowClick,
      parameters: {
        item_nameCategory:
          state.breadcrumbs[state.breadcrumbs.length - 2]?.name,
        item_nameCategorySub:
          state.breadcrumbs[state.breadcrumbs.length - 2]?.name,
        item_description: "Pagination",
        item_message1: page === -1 ? "Left" : "Right",
        item_message2: `${itemsPerPage}`,
      },
    })
  }

  const getLastProductCount = () => {
    const products = pageNumber * itemsPerPage
    // returning last product count on page
    return products > totalItems ? totalItems : products
  }

  if (!totalItems) return null

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      wrap="wrap"
      mr={2}
      gap={1}
      width="auto"
    >
      <Typography>
        {t("pagination.countText", {
          start: ((pageNumber - 1) * itemsPerPage + 1) as unknown as string,
          end: getLastProductCount(),
          total: totalItems as unknown as string,
        })}
      </Typography>
      <IconButton
        size="small"
        onClick={() => handlePageChange(-1)}
        disabled={pageNumber === 1}
        role="button"
        aria-label={t("ariaLabels.previousPage", {
          name: state.breadcrumbs[state.breadcrumbs.length - 2]?.name,
        })}
        data-cy="pagination-prev-btn"
      >
        <NavigateBeforeIcon
          style={{
            fill: pageNumber === 1 ? "#ccc" : "#000",
          }}
        />
      </IconButton>
      <IconButton
        size="small"
        className="btn-pagination-next"
        onClick={() => handlePageChange(1)}
        disabled={pageNumber === totalPaginationPages}
        role="button"
        aria-label={t("ariaLabels.nextPage", {
          name: state.breadcrumbs[state.breadcrumbs.length - 2]?.name,
        })}
        data-cy="pagination-next-btn"
      >
        <NavigateNextIcon
          style={{
            fill: pageNumber === totalPaginationPages ? "#ccc" : "#000",
          }}
        />
      </IconButton>
    </Grid>
  )
}

export default Pagination
