import ProductCardSkeleton from "./ProductCardSkeleton"
import { Grid, useMediaQuery, useTheme } from "@mui/material"

const ProductGridSkeleton = ({ desktopCols = 5 }: { desktopCols?: number }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(1200))

  return (
    <Grid container spacing={isDesktop ? 3 : 2}>
      {[...Array.from(Array(10).keys())].map((_, index) => (
        <Grid
          item
          key={index}
          xs={6} // Mobile: 2 cards per row
          sm={3} // Tablet: 4 cards per row
          md={3} // Tablet: 4 cards per row
          lg={12 / desktopCols}
          xl={12 / desktopCols}
        >
          <ProductCardSkeleton width={isDesktop ? "180px" : "160px"} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ProductGridSkeleton
