import React from "react"
import { Typography, useMediaQuery } from "@mui/material"
import { NotFoundIcon } from "@/icons"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

interface INoResultFound {
  search: string
  isCategory?: boolean
}

const NoResultFound = ({ search, isCategory }: INoResultFound) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))

  const { t } = useTranslation()
  return (
    <>
      {isMobile && (
        <NotFoundIcon
          height={72}
          width={72}
          style={{
            alignSelf: "center",
            marginBottom: 24,
          }}
        />
      )}
      {!isCategory && (isTablet || isDesktop) && (
        <Typography variant="h3" my={3}>
          {t("productSearch.searchResults")}
        </Typography>
      )}
      <Typography
        variant="h3"
        sx={{
          marginBottom: 3,
        }}
        data-cy="no-result-found"
      >
        {t("productSearch.noResultsFound", { searchTerm: search })}
      </Typography>
      <Typography variant="h6">{t("productSearch.weRecommendYou")}</Typography>
      <Typography variant="body1">
        <ul style={{ paddingLeft: 24, marginTop: 12, marginBottom: 0 }}>
          <li style={{ marginBottom: 14 }}>
            {t("productSearch.checkTheSpelling")}
          </li>
          <li style={{ marginBottom: 14 }}>
            {t("productSearch.tryGenericWords")}
          </li>
          <li>{t("productSearch.browseTheCategory")}</li>
        </ul>
      </Typography>
    </>
  )
}

export default NoResultFound
