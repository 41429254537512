import React, { useEffect } from "react"
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { useTheme } from "@emotion/react"
import { ProductFilter, ProductFilterGroup } from "./ProductSearch"
import { t } from "i18next"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { headingStyles } from "../../categories/ItemList"
import generateConfig from "configs/config"

interface LeftBarFiltersProps {
  title?: string
  productFilterGroups: Array<ProductFilterGroup>
  setIsFilterOpen: any
  setProductFilterGroups: React.Dispatch<
    React.SetStateAction<ProductFilterGroup[]>
  >
}

const MAX_WIDTH = 180

const config = generateConfig()

const LeftBarFilters = ({
  title,
  productFilterGroups,
  setProductFilterGroups,
  setIsFilterOpen,
}: LeftBarFiltersProps) => {
  const [expandedFilters, setExpandedFilters] = React.useState<boolean[]>([
    true,
  ])

  const [localFilterGroups, setLocalFilterGroups] =
    React.useState<Array<ProductFilterGroup>>(productFilterGroups)

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down(767))

  useEffect(() => {
    if (!isMobile) {
      setProductFilterGroups(localFilterGroups)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localFilterGroups, setProductFilterGroups])

  useEffect(() => {
    setExpandedFilters([true])
  }, [productFilterGroups])

  const changeHandler = (
    e: any,
    filter: ProductFilter,
    productFilterGroup: ProductFilterGroup,
    j: number,
    k: number
  ) => {
    const isEnterKeyPressed = e.type === "keydown" && e.key === "Enter"
    if (e.type === "change" || isEnterKeyPressed) {
      gaLogEvent({
        eventName: !filter.applied
          ? EventName.navFilter_click
          : EventName.navFilter_unclick,
        parameters: {
          item_message1: productFilterGroup.displayName,
          item_message2: filter.entry,
        },
      })
      const updatedFilterGroups = [...productFilterGroups]
      setLocalFilterGroups((productFilterGroups) => {
        if (updatedFilterGroups.length > 0) {
          updatedFilterGroups[j].filters[k].applied =
            e.target.checked || isEnterKeyPressed
        }
        return updatedFilterGroups
      })
    }
  }

  const handleExpand = (e: any, j: number) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      setExpandedFilters((expandedFilters) => {
        const newExpandedFilters = [...expandedFilters]
        newExpandedFilters[j] = !newExpandedFilters[j]
        return newExpandedFilters
      })
    }
  }

  const renderFilterGroup = (
    productFilterGroup: ProductFilterGroup,
    j: number
  ) => {
    return (
      <React.Fragment key={productFilterGroup.displayName}>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          sx={{
            mb: 2,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            ...(isMobile ? headingStyles.h3 : headingStyles.h4),
          }}
          tabIndex={0}
          role="button"
          aria-expanded={expandedFilters[j]}
          onClick={(e) => handleExpand(e, j)}
          onKeyDown={(e) => handleExpand(e, j)}
        >
          {productFilterGroup.displayName}
          {expandedFilters[j] ? (
            <ExpandMore
              sx={{ ml: "auto", color: theme.palette.ctaPrimary.main }}
            />
          ) : (
            <ExpandLess
              sx={{ ml: "auto", color: theme.palette.ctaPrimary.main }}
            />
          )}
        </Typography>
        <Collapse
          in={expandedFilters[j]}
          timeout="auto"
          unmountOnExit
          sx={{ minHeight: "100% !important" }}
        >
          <Stack gap={2}>
            {productFilterGroup.filters.map(
              (filter, k) =>
                filter.entry && (
                  <FormControlLabel
                    sx={{
                      ml: "-5px",
                      mr: 0,
                      color: theme.palette.textSecondary.dark,
                      "> span.MuiTypography-root": {
                        width: isMobile ? "calc(100% - 60px)" : MAX_WIDTH - 30,
                        display: "flex",
                        gap: 0.5,
                        "> span": {
                          whiteSpace: "nowrap",
                        },
                        " > .entry": {
                          minWidth: 0,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      },
                    }}
                    key={filter.entry}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          color: "textMuted.light",
                          "&.MuiCheckbox-root": {
                            padding: "4px",
                            mr: "4px",
                          },
                          "> span": {
                            whiteSpace: "nowrap",
                            fontSize: "15px",
                          },
                          " > .entry": {
                            minWidth: 0,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                          "&.Mui-focusVisible": {
                            outline: "2px solid #ccc",
                            backgroundColor: "#efefef",
                          },
                        }}
                        color="ctaPrimary"
                        checked={filter.applied}
                        onChange={(e) => {
                          changeHandler(e, filter, productFilterGroup, j, k)
                        }}
                        onKeyDown={(e) => {
                          changeHandler(e, filter, productFilterGroup, j, k)
                        }}
                        key={filter.entry}
                        data-cy="category-filter"
                      />
                    }
                    label={
                      <>
                        <span className="entry" title={filter.entry}>
                          {filter.entry}
                        </span>
                        {/* Commented for - https://dev.azure.com/prestoq/Retailer%20Platform/_workitems/edit/59238 */}
                        {/* <span>({filter.count})</span> */}
                      </>
                    }
                  />
                )
            )}
          </Stack>
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <Stack
      sx={{
        width: isMobile ? "100%" : MAX_WIDTH,
        flexGrow: 1,
        flexShrink: 1,
      }}
    >
      {title && !isMobile && (
        <Typography
          variant="h2"
          color={theme.palette.textPrimary.main}
          mb={3}
          sx={{
            ...headingStyles.h2,
          }}
        >
          {title}
        </Typography>
      )}
      {productFilterGroups.map((productFilterGroup, j) =>
        productFilterGroup.filters.length ? (
          productFilterGroup.displayName === "In-Stock" ? (
            <>
              {config.inStockActive
                ? renderFilterGroup(productFilterGroup, j)
                : null}
            </>
          ) : (
            renderFilterGroup(productFilterGroup, j)
          )
        ) : null
      )}
      {productFilterGroups.filter((obj) => obj?.filters.length).length
        ? isMobile && (
            <>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="end"
                alignSelf="stretch"
                width="inherit"
                py={2}
                sx={{
                  position: "sticky",
                  bottom: 0,
                  right: 0,
                  bgcolor: "background.paper",
                  zIndex: 100,
                  height: "100%",
                }}
              >
                <Button
                  color="ctaPrimary"
                  variant="text"
                  sx={{
                    mr: "auto",
                    backgroundColor: "ctaPrimary.main",
                    color: "bgPrimary.light",
                    borderRadius: 2,
                    "&:hover": {
                      backgroundColor: "ctaPrimary.dark",
                    },
                  }}
                  onClick={(e) => {
                    setIsFilterOpen(false)
                    e.preventDefault()
                    setProductFilterGroups(localFilterGroups)
                  }}
                  aria-label={t("productSearch.seeResults")}
                >
                  {t("productSearch.seeResults")}
                </Button>
                <Button
                  color="ctaPrimary"
                  sx={{
                    ml: "auto",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "ctaPrimary.dark",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setIsFilterOpen(false)
                    setProductFilterGroups((productFilterGroups) =>
                      productFilterGroups.map((productFilterGroup) => ({
                        ...productFilterGroup,
                        filters: productFilterGroup.filters.map((filter) => ({
                          ...filter,
                          applied: false,
                        })),
                      }))
                    )
                    gaLogEvent({
                      eventName: EventName.navFilter_clearAll,
                      parameters: {
                        item_location: t("productSearch.appliedFilters"),
                      },
                    })
                  }}
                  aria-label={t("productSearch.clearAll")}
                >
                  {t("productSearch.clearAll")}
                </Button>
              </Stack>
            </>
          )
        : null}
    </Stack>
  )
}

export default LeftBarFilters
